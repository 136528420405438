import { environment } from "src/environments/environment";

export class PathRest {
    // static readonly GET_LOGIN = environment.base_url + '/vpc-security/v1/api/reverse-proxy/security-api/security/login';
    // static readonly GET_LEADS = environment.base_url + '/vpc-leads/v1/api/reverse-proxy/leads-api/leads/leads/listarLeads';
    // static readonly GET_CHECK_TOKEN = environment.base_url + '/vpc-security/v1/api/reverse-proxy/security-api/security/oauth/check_token';
    // static readonly GET_LOGOUT = environment.base_url + '/vpc-security/v1/api/reverse-proxy/security-api/security/logout';
    // static readonly GET_SEARCH_CAMPAIGN = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/search-campaign';
    // static readonly GET_REGISTER_DETAIL_LEADS = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/register-detail-lead';
    // static readonly GET_LIST_CAMPAIGN = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/list-campaign';
    // static readonly GET_COMMONS = environment.base_url + '/vpc-commons/v1/api/reverse-proxy/commons-api/commons/commons/listByTable';
    // static readonly GET_UPDATE_STATE_CAMPAIGN = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/update-status-campaing';
    // static readonly DELETE_CONTACT = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/contact/delete-contact';
    // static readonly CREATESHORT_CONTACT = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/contact/process-contact-short-massive';
    // static readonly GET_CONTACT = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/contact/list-contact';
    // static readonly GET_CUSTOMER_WITH_UNIQUE_CODE = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/customer/search-customer';
    // static readonly GET_CONTACT_BY_ENTERPRISES = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/contact/list-contact-dual-customer';
    // static readonly GET_LIST_CONTACT_BY_ENTERPRISES = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/contact/list-contact-dual-contact';
    // static readonly GET_LAST_CONTACTS = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/contact/list-last-contact';
    // static readonly GET_DETAIL_CATALOG = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign-format/detail-catalog';
    // static readonly POST_REGISTER_CAMPAIGN_ATTRIBUTE = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/insert-campaign-general-attribute';
    // static readonly POST_REGISTER_CAMPAIGN_STATES = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/insert-campaign-state-substate';
    // static readonly POST_REGISTER_CAMPAIGN_PRODUCTS = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/insert-campaign-product-subproduct';
    // static readonly POST_REGISTER_ENOTE_OPERATION = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/register-or-update';
    // static readonly DELETE_ENOTE_OPERATION = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/delete-operation';
    // static readonly GET_ENOTE_OPERATION = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/list-operations';
    // static readonly GET_ENOTE_RESUME = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/v2/resume/executive';
    // static readonly POST_TOKEN_BO = environment.base_url + '/vpc-security/v1/api/reverse-proxy/security-api/security/getTockenBO';
    // static readonly POST_ARBOL_DIARIO = environment.base_url + '/vpc-security/v1/api/reverse-proxy/security-api/security/getArbolDiario';
    // static readonly POST_UPDATE_CAMPAIGN_ATTRIBUTE = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/update-campaign-general-attribute';
    // static readonly POST_UPDATE_CAMPAIGN_STATES = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/update-campaign-state-substate';
    // static readonly POST_UPDATE_CAMPAIGN_PRODUCTS = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/campaign/update-campaign-product-subproduct';
    // static readonly GET_CLIENTS = environment.base_url + '/vpc-leads/v1/api/reverse-proxy/leads-api/leads/customer/customerList';
    // static readonly GET_FAMILY_CLIENTS = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/client-family/variable-list';
    // static readonly GET_HIERARCHY = environment.base_url + '/vpc-users/v1/api/reverse-proxy/users-api/users/users/list-hierarchies';
    // static readonly GET_ENOTE_RESUME_JEFE = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/v3/resume/boss';
    // static readonly GET_ENOTE_JEFE_NEW = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/boss/list-pipeline';
    // static readonly GET_ENOTE_JEFE_FALLS = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/boss/list-fall';
    // static readonly GET_ENOTE_JEFE_LOSSES = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/boss/list-pipeline/loss';
    // static readonly PROCESS_OFFER_VALUE_IMAGE = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/offer-value/process-offer-value-ima';
    // static readonly PROCESS_OFFER_VALUE_TEXT = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/offer-value/process-offer-value-text';
    // static readonly SEARCH_OFFER_VALUE = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/offer-value/search-offer-value';
    // static readonly LIST_PRODUCT_WITH_FILE = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/productFile/listProductWithFile';
    // static readonly LIST_PRODUCT_FOLDER_FILE = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/productFile/listProductFolderFile';
    // static readonly PROCESS_PRODUCT_DOCUMENT = environment.base_url + '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads/product-document/process-product-document';
    // static readonly GET_LIST_CLIENTS_NPS = environment.base_url + '/vpc-leads/v1/api/reverse-proxy/leads-api/leads/customer/list-customer-nps';
    // static readonly GET_LIST_CONTACTS_CLIENTS_NPS = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/contact/list-contact-universe';
    // static readonly GET_PERIOD_ACTIVE = environment.base_url + '/vpc-leads/v1/api/reverse-proxy/leads-api/leads/period/search-perid-active';
    // static readonly GET_MANTENIMIENTO_CONTACTS_NPS = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/contact/process-contact-universe';
    // static readonly GET_CHANGE_STATE_CONTACTS_NPS = environment.base_url + '/vpc-contact/v1/api/reverse-proxy/contact-api/contact/contact/change-contact-universe';
    // static readonly REGISTER_AMOUNT_CERTERO = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/deposit/accurate/closing-balance-projection/save'

    // // Instructivos
    // static readonly DELETE_PRIORITY = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/productFile/deletePriority';
    // static readonly DELETE_REPORT = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/reportFile/deleteReport';
    // static readonly CREATE_REPORT = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/reportFile/registerReport';
    // static readonly UPDATE_REPORT = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/reportFile/updateReport';
    // static readonly ADD_PRIORITY = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/productFile/registerPriority';
    // static readonly LIST_PRODUCTS = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/productFile/listProductWithFile';
    // static readonly LIST_FOLDERS = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/productFile/listFolder';
    // static readonly URL_DOWNLOAD = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/productFile/downloadFileProduct?idProductFile=';
    // static readonly URL_DOWNLOAD_REPORTs = 'http://20.85.112.237:8080' + '/api/reverse-proxy/reporting-api/reporting/reportFile/downloadFileReport?idReportFile=';
    // static readonly URL_DOWNLOAD_REPORT = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/reportFile/downloadFileReport?idReportFile=';
    // static readonly GET_INSTRUCTIONS = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/productFile/listFileProduct';
    // static readonly GET_ENOTE_JEFE_RANKING = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/enote/boss/v1/ranking/executive';
    // static readonly GET_REPORTS = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/reportFile/listReportFile';


    // static readonly CREATE_REPORTs = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/reportFile/registerReport';
    // static readonly CREATE_REPORT = environment.base_url + '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting/reportFile/registerReport';
    // static readonly GET_LOGIN = environment.base_url + '/api/reverse-proxy/security-api/security/login';
    // static readonly GET_LEADS = environment.base_url + '/api/reverse-proxy/leads-api/leads/leads/listarLeads';
    // static readonly GET_CHECK_TOKEN = environment.base_url + '/api/reverse-proxy/security-api/security/oauth/check_token';
    static readonly GET_LOGOUT = environment.base_url + '/api/reverse-proxy/security-api/security/logout';
    // static readonly GET_SEARCH_CAMPAIGN = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/search-campaign';
    // static readonly GET_REGISTER_DETAIL_LEADS = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/register-detail-lead';
    // static readonly GET_LIST_CAMPAIGN = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/list-campaign';
    // static readonly GET_COMMONS = environment.base_url + '/api/reverse-proxy/commons-api/commons/commons/listByTable';
    // static readonly GET_UPDATE_STATE_CAMPAIGN = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/update-status-campaing';
    // static readonly DELETE_CONTACT = environment.base_url + '/api/reverse-proxy/contact-api/contact/contact/delete-contact';
    // static readonly CREATESHORT_CONTACT = environment.base_url + '/api/reverse-proxy/contact-api/contact/contact/process-contact-short-massive';
    // static readonly GET_CONTACT = environment.base_url + '/api/reverse-proxy/contact-api/contact/contact/list-contact';
    // static readonly GET_CUSTOMER_WITH_UNIQUE_CODE = environment.base_url + '/api/reverse-proxy/contact-api/contact/customer/search-customer';
    // static readonly GET_CONTACT_BY_ENTERPRISES = environment.base_url + '/api/reverse-proxy/contact-api/contact/contact/list-contact-dual-view';
    // static readonly GET_LIST_CONTACT_BY_ENTERPRISES = environment.base_url + '/api/reverse-proxy/contact-api/contact/contact/list-contact-dual-contact';
    // static readonly GET_LAST_CONTACTS = environment.base_url + '/api/reverse-proxy/contact-api/contact/contact/list-last-contact';
    // static readonly GET_DETAIL_CATALOG = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign-format/detail-catalog';
    // static readonly POST_REGISTER_CAMPAIGN_ATTRIBUTE = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/insert-campaign-general-attribute';
    // static readonly POST_REGISTER_CAMPAIGN_STATES = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/insert-campaign-state-substate';
    // static readonly POST_REGISTER_CAMPAIGN_PRODUCTS = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/insert-campaign-product-subproduct';
    // static readonly POST_REGISTER_ENOTE_OPERATION = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/register-or-update';
    // static readonly DELETE_ENOTE_OPERATION = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/delete-operation';
    // static readonly GET_ENOTE_OPERATION = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/list-operations';
    // static readonly GET_ENOTE_RESUME = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/v2/resume/executive';
    // static readonly POST_TOKEN_BO = environment.base_url + '/api/reverse-proxy/security-api/security/getTockenBO';
    // static readonly POST_ARBOL_DIARIO = environment.base_url + '/api/reverse-proxy/security-api/security/getArbolDiario';
    // static readonly POST_UPDATE_CAMPAIGN_ATTRIBUTE = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/update-campaign-general-attribute';
    // static readonly POST_UPDATE_CAMPAIGN_STATES = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/update-campaign-state-substate';
    // static readonly POST_UPDATE_CAMPAIGN_PRODUCTS = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/campaign/update-campaign-product-subproduct';
    // static readonly GET_CLIENTS = environment.base_url + '/api/reverse-proxy/leads-api/leads/customer/customerList';
    // static readonly GET_FAMILY_CLIENTS = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/client-family/variable-list';
    // static readonly GET_HIERARCHY = environment.base_url + '/api/reverse-proxy/users-api/users/users/list-hierarchies';
    // static readonly GET_ENOTE_RESUME_JEFE = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/v3/resume/boss';
    // static readonly GET_ENOTE_JEFE_NEW = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/boss/list-pipeline';
    // static readonly GET_ENOTE_JEFE_FALLS = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/boss/list-fall';
    // static readonly GET_ENOTE_JEFE_LOSSES = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/boss/list-pipeline/loss';
    // static readonly GET_ENOTE_JEFE_RANKING = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/boss/v1/ranking/executive';
    // static readonly PROCESS_OFFER_VALUE_IMAGE = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/offer-value/process-offer-value-ima';
    // static readonly PROCESS_OFFER_VALUE_TEXT = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/offer-value/process-offer-value-text';
    // static readonly SEARCH_OFFER_VALUE = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/offer-value/search-offer-value';
    // static readonly LIST_PRODUCT_WITH_FILE = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/productFile/listProductWithFile';
    // static readonly LIST_PRODUCT_FOLDER_FILE = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/productFile/listProductFolderFile';
    // static readonly PROCESS_PRODUCT_DOCUMENT = environment.base_url + '/api/reverse-proxy/config-leads-api/config-leads/product-document/process-product-document';
    // static readonly GET_LIST_CLIENTS_NPS = environment.base_url + '/api/reverse-proxy/leads-api/leads/customer/list-customer-nps';
    // static readonly GET_LIST_CONTACTS_CLIENTS_NPS = environment.base_url + '/api/reverse-proxy/contact-api/contact/contact/list-contact-universe';
    // static readonly GET_PERIOD_ACTIVE = environment.base_url + '/api/reverse-proxy/leads-api/leads/period/search-perid-active';
    // static readonly GET_MANTENIMIENTO_CONTACTS_NPS = environment.base_url + '/api/reverse-proxy/contact-api/contact/contact/process-contact-universe';
    // static readonly GET_CHANGE_STATE_CONTACTS_NPS = environment.base_url + '/api/reverse-proxy/contact-api/contact/contact/change-contact-universe';
    // static readonly REGISTER_AMOUNT_CERTERO = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/enote/deposit/accurate/closing-balance-projection/save';

    // // Instructivos
    // static readonly DELETE_PRIORITY = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/productFile/deletePriority';
    // static readonly ADD_PRIORITY = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/productFile/registerPriority';
    // static readonly LIST_PRODUCTS = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/productFile/listProductWithFile';
    // static readonly LIST_FOLDERS = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/productFile/listFolder';
    // static readonly URL_DOWNLOAD = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/productFile/downloadFileProduct?idProductFile=';
    // static readonly GET_INSTRUCTIONS = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/productFile/listFileProduct';
    // static readonly GET_REPORTS = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/reportFile/listReportFile';

    // static readonly DELETE_REPORT = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/reportFile/deleteReport';
    // static readonly URL_DOWNLOAD_REPORT = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/reportFile/downloadFileReport?idReportFile=';
    // static readonly UPDATE_REPORT = environment.base_url + '/api/reverse-proxy/reporting-api/reporting/reportFile/updateReport';


    // NEW DATA

    // static readonly GET_LOGIN = `${environment.base_url}${environment.security_path}/login`;
    // static readonly GET_LEADS = `${environment.base_url}${environment.leads_path}/leads/listarLeads`;
    // static readonly GET_LEADS_AMOUND = `${environment.base_url}${environment.leads_path}/leads/get-lead-amount`;
    // static readonly GET_CHECK_TOKEN = `${environment.base_url}${environment.security_path}/oauth/check_token`;
    // static readonly GET_LOGOUT = `${environment.base_url}${environment.security_path}/logout`;
    // static readonly GET_SEARCH_CAMPAIGN = `${environment.base_url}${environment.config_leads_path}/campaign/search-campaign`;
    // static readonly GET_SEARCH_CAMPAIGN_ATTRIBUTE = `${environment.base_url}${environment.config_leads_path}/campaign/search-campaign-lead-attribute`;
    // static readonly GET_SEARCH_CAMPAIGN_ELEMENT = `${environment.base_url}${environment.config_leads_path}/campaign/search-campaign-lead-element`;
    // static readonly GET_REGISTER_DETAIL_LEADS = `${environment.base_url}${environment.config_leads_path}/campaign/register-detail-lead`;
    // static readonly GET_LIST_CAMPAIGN = `${environment.base_url}${environment.config_leads_path}/campaign/list-campaign`;
    static readonly GET_COMMONS = `${environment.base_url}${environment.commons_path}/commons/listByTable`;
    // static readonly GET_UPDATE_STATE_CAMPAIGN = `${environment.base_url}${environment.config_leads_path}/campaign/update-status-campaing`;
    static readonly DELETE_CONTACT = `${environment.base_url}${environment.contact_path}/contact/delete-contact`;
    static readonly CREATESHORT_CONTACT = `${environment.base_url}${environment.contact_path}/contact/process-contact-short-massive`;
    static readonly GET_CONTACT = `${environment.base_url}${environment.contact_path}/contact/list-contact`;
    static readonly GET_CONTACT_TYPE_UNIVERSE = `${environment.base_url}${environment.contact_path}/contact/validation-contact-universe`;
    static readonly GET_CUSTOMER_WITH_UNIQUE_CODE = `${environment.base_url}${environment.contact_path}/customer/search-customer`;
    static readonly GET_CONTACT_BY_ENTERPRISES = `${environment.base_url}${environment.contact_path}/contact/list-contact-dual-customer`;
    static readonly GET_LIST_CONTACT_BY_ENTERPRISES = `${environment.base_url}${environment.contact_path}/contact/list-contact-dual-contact`;
    // static readonly GET_LAST_CONTACTS = `${environment.base_url}${environment.contact_path}/contact/list-last-contact`;
    // static readonly GET_DETAIL_CATALOG = `${environment.base_url}${environment.config_leads_path}/campaign-format/detail-catalog`;
    // static readonly POST_REGISTER_CAMPAIGN_ATTRIBUTE = `${environment.base_url}${environment.config_leads_path}/campaign/insert-campaign-general-attribute`;
    // static readonly POST_REGISTER_CAMPAIGN_STATES = `${environment.base_url}${environment.config_leads_path}/campaign/insert-campaign-state-substate`;
    // static readonly POST_REGISTER_CAMPAIGN_PRODUCTS = `${environment.base_url}${environment.config_leads_path}/campaign/insert-campaign-product-subproduct`;
    // static readonly CAMPAIGN_SUMMARY = `${environment.base_url}${environment.config_leads_path}/campaign/summary-campaign`;
    // static readonly POST_REGISTER_ENOTE_OPERATION = `${environment.base_url}${environment.reporting_path}/enote/register-or-update`;
    // static readonly DELETE_ENOTE_OPERATION = `${environment.base_url}${environment.reporting_path}/enote/delete-operation`;
    // static readonly GET_ENOTE_OPERATION = `${environment.base_url}${environment.reporting_path}/enote/list-operations`;
    // static readonly GET_ENOTE_RESUME = `${environment.base_url}${environment.reporting_path}/enote/v2/resume/executive`;
    // static readonly POST_TOKEN_BO = `${environment.base_url}${environment.security_path}/getTockenBO`;
    // static readonly POST_ARBOL_DIARIO = `${environment.base_url}${environment.security_path}/getArbolDiario`;
    // static readonly POST_UPDATE_CAMPAIGN_ATTRIBUTE = `${environment.base_url}${environment.config_leads_path}/campaign/update-campaign-general-attribute`;
    // static readonly POST_UPDATE_CAMPAIGN_STATES = `${environment.base_url}${environment.config_leads_path}/campaign/update-campaign-state-substate`;
    // static readonly POST_UPDATE_CAMPAIGN_PRODUCTS = `${environment.base_url}${environment.config_leads_path}/campaign/update-campaign-product-subproduct`;
    // static readonly GET_CLIENTS = `${environment.base_url}${environment.leads_path}/customer/customerList`;
    // static readonly GET_FAMILY_CLIENTS = `${environment.base_url}${environment.reporting_path}/client-family/variable-list`;
    // static readonly GET_HIERARCHY = `${environment.base_url}${environment.users_path}/users/list-hierarchies`;
    // static readonly GET_ENOTE_RESUME_JEFE = `${environment.base_url}${environment.reporting_path}/enote/v3/resume/boss`;
    // static readonly GET_ENOTE_JEFE_NEW = `${environment.base_url}${environment.reporting_path}/enote/boss/list-pipeline`;
    // static readonly GET_ENOTE_JEFE_FALLS = `${environment.base_url}${environment.reporting_path}/enote/boss/list-fall`;
    // static readonly GET_ENOTE_JEFE_LOSSES = `${environment.base_url}${environment.reporting_path}/enote/boss/list-pipeline/loss`;
    // static readonly PROCESS_OFFER_VALUE_IMAGE = `${environment.base_url}${environment.config_leads_path}/offer-value/process-offer-value-ima`;
    // static readonly PROCESS_OFFER_VALUE_TEXT = `${environment.base_url}${environment.config_leads_path}/offer-value/process-offer-value-text`;
    // static readonly DELETE_OFFER_VALUE = `${environment.base_url}${environment.config_leads_path}/offer-value/delete-offer-value`;
    // static readonly SEARCH_OFFER_VALUE = `${environment.base_url}${environment.config_leads_path}/offer-value/search-offer-value`;
    // static readonly LIST_PRODUCT_WITH_FILE = `${environment.base_url}${environment.config_leads_path}/productFile/listProductWithFile`;
    // static readonly LIST_PRODUCT_FOLDER_FILE = `${environment.base_url}${environment.config_leads_path}/productFile/listProductFolderFile`;
    // static readonly PROCESS_PRODUCT_DOCUMENT = `${environment.base_url}${environment.config_leads_path}/product-document/process-product-document`;
    // static readonly GET_LIST_CLIENTS_NPS = `${environment.base_url}${environment.leads_path}/customer/list-customer-nps`;
    // static readonly GET_LIST_CONTACTS_CLIENTS_NPS = `${environment.base_url}${environment.contact_path}/contact/list-contact-universe`;
    // static readonly GET_PERIOD_ACTIVE = `${environment.base_url}${environment.leads_path}/period/search-perid-active`;
    // static readonly GET_MANTENIMIENTO_CONTACTS_NPS = `${environment.base_url}${environment.contact_path}/contact/process-contact-universe`;
    // static readonly GET_CHANGE_STATE_CONTACTS_NPS = `${environment.base_url}${environment.contact_path}/contact/change-contact-universe`;
    static readonly DOWNLOAD_NPS = `${environment.base_url}${environment.contact_path}/contact/download-nps-client?period=`;
    static readonly LOAD_NPS_DATA = `${environment.base_url}${environment.contact_path}/contact/load-nps-data`;
    // static readonly REGISTER_AMOUNT_CERTERO = `${environment.base_url}${environment.reporting_path}/enote/deposit/accurate/closing-balance-projection/save`;


    // Instructivos
  // static readonly CREATE_INST = `${environment.base_url}${environment.reporting_path}/productFile/registerProductFile`;
  // static readonly UPDATE_INST = `${environment.base_url}${environment.reporting_path}/productFile/updateProductFile`;


    static readonly DELETE_PRIORITY = `${environment.base_url}${environment.reporting_path}/productFile/deletePriority`;
    static readonly DELETE_REPORT = `${environment.base_url}${environment.reporting_path}/reportFile/deleteReport`;
    static readonly CREATE_REPORT = `${environment.base_url}${environment.reporting_path}/reportFile/registerReport`;
    static readonly UPDATE_REPORT = `${environment.base_url}${environment.reporting_path}/reportFile/updateReport`;
    static readonly ADD_PRIORITY = `${environment.base_url}${environment.reporting_path}/productFile/registerPriority`;
    // static readonly LIST_PRODUCTS = `${environment.base_url}${environment.reporting_path}/productFile/listProductWithFile`;
    static readonly LIST_FOLDERS = `${environment.base_url}${environment.reporting_path}/productFile/listFolder`;
    static readonly URL_DOWNLOAD = `${environment.base_url}${environment.reporting_path}/productFile/downloadFileProduct?idProductFile=`;
    static readonly URL_DOWNLOAD_REPORTs = `${environment.base_url}${environment.reporting_path}/reportFile/downloadFileReport?idReportFile=`;
    static readonly URL_DOWNLOAD_REPORT = `${environment.base_url}${environment.reporting_path}/reportFile/downloadFileReport?idReportFile=`;
    // static readonly GET_INSTRUCTIONS = `${environment.base_url}${environment.reporting_path}/productFile/listFileProduct`;
    // static readonly GET_ENOTE_JEFE_RANKING = `${environment.base_url}${environment.reporting_path}/enote/boss/v1/ranking/executive`;
    static readonly GET_REPORTS = `${environment.base_url}${environment.reporting_path}/reportFile/listReportFile`;

    // static readonly DELETE_INSTRUCTIVE = `${environment.base_url}${environment.reporting_path}/productFile/deleteProductFile`;

    //Resectorization
    static readonly GET_LIST_RESECTORIZATION = `${environment.base_url}${environment.leads_path}/customer/get-resectorization`;
    static readonly GET_LIST_CUSTOMER_SOLICITUD = `${environment.base_url}${environment.leads_path}/customer/get-customer`;
    static readonly GET_LIST_DESTINY_SECTORIST = `${environment.base_url}${environment.users_path}/shortCode/list-codes-subordinate-special`;
    static readonly CREATE_NEW_RESECTORIZATION = `${environment.base_url}${environment.leads_path}/customer/insert-resectorization`;

    //CE
    static readonly GET_LIST_VOUCHER_ELECTRONIC = `${environment.base_url}${environment.leads_path}/customer/list-customer-ce`;
    static readonly GET_LIST_CONTACTS_CLIENTS_CE = `${environment.base_url}${environment.contact_path}/contact/list-contact-universe`;
    static readonly GET_UPDATE_CONTACTS_CE = `${environment.base_url}${environment.contact_path}/contact/process-contact-universe`;
    static readonly GET_CHANGE_STATE_CONTACTS_CE = `${environment.base_url}${environment.contact_path}/contact/change-contact-universe`;


  //LEADS
  // static readonly GET_STATE = `${environment.base_url}${environment.config_leads_path}/campaign-format/state-catalog`;
  // static readonly GET_SUB_STATE = `${environment.base_url}${environment.config_leads_path}/campaign-format/substate-catalog`;
  // static readonly GET_CAMPAIGN = `${environment.base_url}${environment.config_leads_path}/campaign/info-campaing`;

  //embebidos
  // static readonly GET_EMBED_FICHA_CLIENTE = `${environment.base_url}${environment.users_path}/embed/getembedinfo`;

}
