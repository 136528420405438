import { Injectable } from '@angular/core';

@Injectable()
export class FileService {

  constructor() { }

  public base64toBlob(b64Data: any, contentType: string, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: blob.type });
  }

  public getContentType(type: string) {
    let contentType = '';
    if (['doc', 'docx'].includes(type)) {
        contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    } else if (['ppt', 'pptx'].includes(type)) {
        contentType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    } else if (type == 'pdf') {
        contentType = 'application/pdf';
    } else if (['xls', 'xlsx'].includes(type)) {
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else {
        contentType = `image/${type}`;
    }
    return contentType;
  }

  public formatFileSize(bytes: number): string {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;
    if (bytes >= gb) {
      return (bytes / gb).toFixed(2) + '.gb';
    } else if (bytes >= mb) {
      return (bytes / mb).toFixed(2) + '.mb';
    } else if (bytes >= kb) {
      return (bytes / kb).toFixed(2) + '.kb';
    } else {
      return bytes + '.bytes';
    }
  }

  public base64ToArrayBuffer(base64: any) {
    const binaryString = atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

}
